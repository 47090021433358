import { AlertType, MetoxItemStatusTypeDisplay } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { AS_BUILT_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay, numberDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const METOX_TABLE_HEADERS = [
    ...STANDARD_COLS,
    {
        text: 'Status',
        value: '_metoxStatus',
        sortable: true,
        noWrap: true
    },
    {
        text: 'Last Regenerated',
        value: '_regenerationDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Current</br>Cycles',
        value: '_currentCycles',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    {
        text: 'Available</br>Cycles',
        value: '_cyclesAvailable',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    {
        text: 'Remaining</br>O-Ring Cycles',
        value: '_remainingPortCycles',
        noEscape: true,
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    {
        text: 'O-Ring expiry',
        value: '_oringExpiryDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    }
];
export const FEMUR_METOX_TABLE_HEADERS = [
    SERIAL_COL,
    AS_BUILT_COL,
    {
        text: 'Status',
        value: '_metoxStatus',
        sortable: true,
        noWrap: true
    },
    {
        text: 'Last Regeneration',
        value: '_regenerationDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Remaining Cycles<br><small>(max 100 [02/03])<br/>(max 70 [04+])</small>',
        value: '_cyclesAvailable',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    {
        text: 'Remaining Port<br/>Cycles<br/><small>(max 27)</small>',
        value: '_remainingPortCycles',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        noEscape: true
    },
    {
        text: 'O-Ring expiration',
        value: '_oringExpiryDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_METOX_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        align: 'center',
        noEscape: true,
        sortable: true,
        text: 'Metox Status',
        value: '_statusIcon'
    }
];
export const transformMetox = (items) => {
    return (items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        // status icon
        .map((item) => {
        const _item = Object.assign({}, item);
        const status = item.status;
        _item._metoxStatus = status?.MetoxStatus?.value
            ? MetoxItemStatusTypeDisplay.get(status?.MetoxStatus?.value) || '-'
            : '-';
        return _item;
    })
        // femur stuff
        .map((item) => {
        const status = item.status;
        const _item = Object.assign({}, item, {
            _regenerationDate: alertableStatusDisplay(status?.lastMetoxRegenerationDate, dateDisplay(status?.lastMetoxRegenerationDate?.value)),
            _remainingPortCycles: alertableStatusDisplay(status?.cycleCountSinceORingChange, numberDisplay(status?.cycleCountSinceORingChange?.requirementRemaining))
        });
        return _item;
    })
        // everything else
        .map((item) => {
        const status = item.status;
        const attributes = item.attributes;
        const alerts = [
            status?.lastMetoxRegenerationDate?.alertType,
            status?.cycleCountSinceORingChange?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _currentCycles: numberDisplay(status?.cycleCount?.value),
            _cyclesAvailable: numberDisplay(status?.cycleCount?.requirementRemaining),
            _cyclesSinceOringChange: numberDisplay(status?.cycleCountSinceORingChange?.value),
            _oringExpiryDate: dateDisplay(attributes?.oRingExpirationDate)
        });
        return _item;
    }));
};
