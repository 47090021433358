import { HardwareType } from '~/types';
export const alertableFields = {
    [HardwareType.EMU]: [
        'item146CycleDueDate',
        'lastFullWaterDumpDate',
        'fullWaterDumpDueDate',
        'lastPartialWaterDumpDate',
        'partialWaterDumpDueDate',
        'waterSampleDueDate',
        'preEvaScrubDueDate',
        'postEvaScrubDueDate',
        'iodinateDueDate',
        'lastIodinateDate',
        'sopCheckOutDueDate',
        'hutWlvtaRemoveReplaceDueDate'
    ],
    [HardwareType.EMU_BATTERY]: [],
    [HardwareType.EMU_BATTERY_LLB]: [
        'batteryState',
        'daysOver50PercentStateOfCharge',
        'lastDischargeDate',
        'expirationDate',
        'autocycleDueDate',
        'cycles'
    ],
    [HardwareType.EMU_BIOCIDE_FILTER]: ['usesAvailable'],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER]: ['volume'],
    [HardwareType.EMU_ION_FILTER]: [],
    [HardwareType.EMU_MICRON_FILTER]: [],
    [HardwareType.EMU_SCRUB_ASSEMBLY]: ['useCount'],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER]: ['iodinateDueDate'],
    [HardwareType.FPS]: ['greaseCirculateDueDate'],
    [HardwareType.HL_BATTERY]: [],
    [HardwareType.HUT_ORU]: ['iodinateDueDate', 'hutWlvtaRemoveReplaceDueDate'],
    [HardwareType.HUT_ORU_SCRUBBER]: ['iodinateDueDate'],
    [HardwareType.IEU_SCU]: ['scrubDueDate', 'lastIodinateDate', 'postEvaScrubDueDate', 'preEvaScrubDueDate'],
    [HardwareType.LCVG]: ['iodinateDueDate'],
    [HardwareType.LEGACY_FPU]: ['returnDueDate'],
    [HardwareType.LIB_CHARGER]: ['calibrationDueDate', 'lastCalibrationDate', 'expirationDate'],
    [HardwareType.LPGT_BATTERY]: [
        'autocycleDueDate',
        'daysOver50PercentStateOfCharge',
        'lastDischargeDate',
        'expirationDate'
    ],
    [HardwareType.LREBA]: [
        'autocycleDueDate',
        'daysOver50PercentStateOfCharge',
        'lastDischargeDate',
        'expirationDate',
        'cycles'
    ],
    [HardwareType.METOX]: ['lastMetoxRegenerationDate', 'cycleCountSinceORingChange'],
    [HardwareType.METOX_SIMULATOR]: ['annualChangeOutDueDate', 'lastAnnualChangeOutDate'],
    [HardwareType.PGT]: ['takDataCollectionDueDate', 'takDataApprovalDueDate'],
    [HardwareType.PGT_BATTERY]: [],
    [HardwareType.REBA]: [],
    [HardwareType.SAFER]: ['lastGn2Percent', 'lastPwrPercent', 'lastBatteryVolts'],
    [HardwareType.SAFER_LPGT_BATTERY]: ['lastDischargeDate', 'expirationDate', 'cycles'],
    [HardwareType.SAFETY_TETHER_55]: ['evaReadiness'],
    [HardwareType.SAFETY_TETHER_85]: ['evaReadiness'],
    [HardwareType.SCOF]: ['lastAnnualCheckOutDate', 'annualCheckOutDueDate'],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER]: ['evaReadiness'],
    [HardwareType.UIA]: [
        'iodinateDueDate',
        'lastIodinateDate',
        'preEvaScrubDueDate',
        'postEvaScrubDueDate',
        'lastScrubDate',
        'scrubDueDate'
    ],
    [HardwareType.UIA_BIOCIDE_FILTER]: ['volume'],
    [HardwareType.WAIST_TETHER]: ['evaReadiness'],
    [HardwareType.EHIP_LIGHT]: ['powerCycleDueDate', 'lastPowerCycleDate'],
    [HardwareType.HAB_HAP_E]: []
};
var fieldDisplay;
(function (fieldDisplay) {
    fieldDisplay["annualChangeOutDueDate"] = "Annual change out due date";
    fieldDisplay["annualCheckOutDueDate"] = "Annual check out due date";
    fieldDisplay["autocycleDueDate"] = "Autocycle due date";
    fieldDisplay["batteryState"] = "Battery state";
    fieldDisplay["calibrationDueDate"] = "Calibration due date";
    fieldDisplay["cycleCountSinceORingChange"] = "Cycle count since oring change";
    fieldDisplay["cycles"] = "Cycles";
    fieldDisplay["daysOver50PercentStateOfCharge"] = "Days over 50 percent state of charge";
    fieldDisplay["evaReadiness"] = "EVA readiness";
    fieldDisplay["expirationDate"] = "Expiration date";
    fieldDisplay["fullWaterDumpDueDate"] = "Full water dump due date";
    fieldDisplay["greaseCirculateDueDate"] = "Grease circulation due date";
    fieldDisplay["hutWlvtaRemoveReplaceDueDate"] = "HUT WLVTA due date";
    fieldDisplay["iodinateDueDate"] = "Iodinate due date";
    fieldDisplay["item146CycleDueDate"] = "Item 146 expiration";
    fieldDisplay["lastAnnualChangeOutDate"] = "Last annual change out date";
    fieldDisplay["lastAnnualCheckOutDate"] = "Last annual check out date";
    fieldDisplay["lastBatteryVolts"] = "Last battery volts";
    fieldDisplay["lastCalibrationDate"] = "Last calibration date";
    fieldDisplay["lastDischargeDate"] = "Last discharge date";
    fieldDisplay["lastFullWaterDumpDate"] = "Last full water dump";
    fieldDisplay["lastGn2Percent"] = "Last GN2 percent";
    fieldDisplay["lastIodinateDate"] = "Last iodinate date";
    fieldDisplay["lastMetoxRegenerationDate"] = "Last METOX regeneration date";
    fieldDisplay["lastPartialWaterDumpDate"] = "Last partial water dump date";
    fieldDisplay["lastPowerCycleDate"] = "Last power cycle date";
    fieldDisplay["lastPwrPercent"] = "Last power percent";
    fieldDisplay["lastScrubDate"] = "Last scrub date";
    fieldDisplay["partialWaterDumpDueDate"] = "Partial water dump due date";
    fieldDisplay["postEvaScrubDueDate"] = "Post EVA scrub due date";
    fieldDisplay["powerCycleDueDate"] = "Power cycle due date";
    fieldDisplay["preEvaScrubDueDate"] = "Pre EVA scrub due date";
    fieldDisplay["returnDueDate"] = "Return due date";
    fieldDisplay["scrubDueDate"] = "Scrub due date";
    fieldDisplay["sopCheckOutDueDate"] = "SOP checkout due date";
    fieldDisplay["takDataApprovalDueDate"] = "TAK data approval due date";
    fieldDisplay["takDataCollectionDueDate"] = "TAK data collection due date";
    fieldDisplay["useCount"] = "Use count";
    fieldDisplay["usesAvailable"] = "Uses available";
    fieldDisplay["volume"] = "Volume";
    fieldDisplay["waterSampleDueDate"] = "Water sample due date";
})(fieldDisplay || (fieldDisplay = {}));
export const alertFieldToText = (field) => {
    return fieldDisplay[field] || `** Missing field display for : ${field} **`;
};
